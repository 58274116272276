<script setup lang="ts">

</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
    <path
      d="m23.33,7.45c0-.13,0-.27,0-.4,0-.37,0-.75-.04-1.13-.15-1.33-.69-2.51-1.6-3.51C20.25.83,18.4.02,16.19,0,14.1,0,12.01,0,9.92,0h-2.7c-1.49,0-2.83.37-3.98,1.09C1.1,2.43.02,4.33,0,6.72,0,8.91,0,11.1,0,13.28v3.28c0,.25,0,.55.04.85.16,1.34.7,2.53,1.62,3.53,1.44,1.56,3.28,2.36,5.48,2.38,1.21,0,2.43,0,3.64,0,.89,0,1.78,0,2.68,0h2.68c1.47,0,2.81-.37,3.96-1.09,2.13-1.34,3.22-3.24,3.23-5.63,0-1.1,0-2.2,0-3.3v-1.65s0-1.4,0-1.4c0-.94,0-1.87,0-2.81Zm-2.2-.84v9.96c0,1.71-.77,2.98-2.35,3.89-.77.44-1.65.67-2.62.67h-2.73c-2.11,0-4.22,0-6.33,0-1.48,0-2.73-.54-3.73-1.58-.76-.79-1.14-1.74-1.15-2.81,0-1.13,0-2.25,0-3.37v-6.59c0-1.71.76-2.98,2.34-3.89.78-.44,1.64-.66,2.56-.66h2.49c.83,0,1.66,0,2.49,0,1.39,0,2.78,0,4.18.01,1.48,0,2.73.54,3.72,1.59.75.79,1.14,1.73,1.14,2.79Z"
      style="fill:currentColor; stroke-width:0px;" />
    <path
      d="m11.7,5.71h0c-3.09,0-5.61,2.46-5.61,5.5,0,1.5.57,2.9,1.62,3.95,1.06,1.06,2.47,1.64,3.98,1.64,3.09,0,5.62-2.48,5.62-5.52,0-1.48-.57-2.87-1.62-3.92-1.06-1.06-2.47-1.65-3.98-1.65Zm3.39,5.55c0,1.83-1.53,3.33-3.41,3.33s-3.39-1.5-3.39-3.34,1.53-3.33,3.41-3.33,3.39,1.5,3.39,3.34Z"
      style="fill:currentColor; stroke-width:0px;" />
    <path
      d="m17.67,4.1c-.76,0-1.38.64-1.38,1.43,0,.39.15.75.42,1.02.26.26.6.4.96.4h0c.75,0,1.37-.64,1.37-1.42s-.62-1.43-1.38-1.43Z"
      style="fill:currentColor; stroke-width:0px;" />
  </svg>
</template>

<style scoped>

</style>
